import * as React from "react"
import { useState, useRef, useEffect } from "react"
import ApiLoader from "../components/api-loader"
import Seo from "../components/seo"
import {
  openAIDavinci_getAnswerFromModal,
  postKnowledgeBaseFeedback,
  renoAssistantGetResponse,
  renoGptGetResponse,
  updateKnowledgeBaseFeedback,
} from "../services/api-endpoints"
import { getAccessToken, getUser } from "../services/auth"
import {
  authCallbackUrl,
  authClientId,
  backAuthServerUrl,
  frontClientUrl,
} from "../services/constant"

export const isBrowser = () => typeof window !== "undefined"

export default function EvitatKnowledgeBase() {
  const isBrowser = () => typeof window !== "undefined"
  const loginUrl =
    backAuthServerUrl() +
    "/oauth/authorize?response_type=code&client_id=" +
    authClientId() +
    "&redirect_uri=" +
    authCallbackUrl() +
    "&scope=read"

  useEffect(() => {
    //user needs to login before using evitat knowledge base
    const isUserIdPresent = getUser().uid
    const userToken = getAccessToken()

    if (!isUserIdPresent || !userToken) {
      window.location.replace(loginUrl)
    }
  }, [])

  const query = useRef(null)
  const [responseText, setResponseText] = useState("")
  let search = isBrowser() && window.location.search
  let params = new URLSearchParams(search)
  const [queryParam, setQueryParam] = useState(params.get("query"))
  const [inputValue, setInputValue] = useState()
  const [showFeedback, setShowFeedback] = useState(false)
  const [savedAnswerEntryID, setSavedAnswerEntryID] = useState()
  const [showFeedbackReplyMessage, setShowFeedbackReplyMessage] =
    useState(false)
  const helpfulFeedbackRes = "up"
  const notHelpfulFeedbackRes = "down"
  queryParam && handleDirectQuery(queryParam)

  function displayAnswer(ans) {
    setResponseText(ans)
    setShowFeedbackReplyMessage(false)
    setShowFeedback(true)
  }

  function handleDirectQuery(query) {
    setQueryParam(undefined)
    if (inputValue !== query) setInputValue(query)

    openAIDavinci_getAnswerFromModal(query).then(res => {
      if (res?.data?.code === 200) {
        const openAIRes = res?.data?.data
        displayAnswer(openAIRes.response?.choices[0]?.text)
      }
    })
  }

  function isDevEnv() {
    return (
      frontClientUrl().includes("dev.") ||
      frontClientUrl().includes("localhost")
    )
  }

  function getQueryResult(e) {
    e.preventDefault()

    renoAssistantGetResponse(query.current.value, "generel_query_assistant").then(res => {
      if (res?.data?.code === 200) {
        const openAIRes = res?.data?.data

        const userData = getUser()

        let completeMessage = ""
        openAIRes.response.messages.forEach(message => {
          completeMessage = completeMessage + message + "\n\n\n"
        })

        const data = {
          user_uid: userData.uid,
          question: inputValue,
          answer_from_model: completeMessage,
          source: "web",
        }

        postKnowledgeBaseFeedback(data).then(res => {
          if (res?.data?.code === 200) {
            const kbData = res?.data?.data

            setSavedAnswerEntryID(kbData.id)
          }
        })
        displayAnswer(completeMessage)
      }
    })

    // renoGptGetResponse(query.current.value).then(res => {
    //   if (res?.data?.code === 200) {
    //     const openAIRes = res?.data?.data

    //     const userData = getUser()

    //     const data = {
    //       user_uid: userData.uid,
    //       question: inputValue,
    //       answer_from_model: openAIRes.response,
    //       source: "web",
    //     }

    //     postKnowledgeBaseFeedback(data).then(res => {
    //       if (res?.data?.code === 200) {
    //         const kbData = res?.data?.data

    //         setSavedAnswerEntryID(kbData.id)
    //       }
    //     })
    //     displayAnswer(openAIRes.response)
    //   }
    // })
  }

  function handleFeedbackButtonClick(feedbackRes) {
    const userData = getUser()
    const data = {
      user_uid: userData.uid,
      question: inputValue,
      answer_from_model: responseText,
      user_feedback: feedbackRes,
      source: "web",
    }

    updateKnowledgeBaseFeedback(savedAnswerEntryID, data).then(res => {
      if (res?.data?.code === 200) {
        setShowFeedback(false)
        setShowFeedbackReplyMessage(true)
      }
    })
  }

  return (
    <>
      <Seo title="Evitat Knowledge Base - Search" />
      <div className="sm:max-w-4xl mx-auto">
        <ApiLoader />
        <div className="mt-8 xl:mt-0 ">
          <h3 className="text-lg font-extrabold text-gray-900">
            Evitat Knowledge Base - Search
          </h3>
          <form
            className="grid grid-flow-row-dense grid-cols-3"
            onSubmit={getQueryResult}
          >
            <label htmlFor="query-search" className="sr-only">
              Evitat Knowledge Base - Search
            </label>
            <input
              ref={query}
              value={inputValue}
              onChange={searchQuery =>
                setInputValue(searchQuery?.target?.value)
              }
              type="text"
              name="query"
              id="query"
              autoComplete="email"
              required
              className="col-span-2 appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
              placeholder="Enter your reno query here"
            />
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="w-full bg-green-500 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-green-600 focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Search
              </button>
            </div>
          </form>
          <div className="mt-6">
            <p className="mb-3 font-light text-black dark:text-black">{
            responseText.split("\n").map(function(item, idx) {
                return (
                    <span key={idx}>
                        {item}
                        <br/>
                    </span>
                )
            })
          }
            </p>
          </div>
          {showFeedbackReplyMessage && (
            <div className="mt-6">
              <h3 className="text-base font-extrabold">
                <i>Thanks for the feedback!</i>
              </h3>
            </div>
          )}
          {showFeedback && (
            <div className="mt-6">
              <h3 className="text-base font-extrabold">
                <i>Was this answer helpful?</i>
              </h3>
              <div className="flex space-x-3">
                <button
                  onClick={() => handleFeedbackButtonClick(helpfulFeedbackRes)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-green-300 hover:text-green-500 font-bold"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                    />
                  </svg>
                </button>
                <button
                  onClick={() =>
                    handleFeedbackButtonClick(notHelpfulFeedbackRes)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-red-300 hover:text-red-500 font-bold"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
